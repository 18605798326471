.shop{
    .item{
        box-shadow: 0 0 30px 0px rgba(128, 128, 128, 0.329);
        padding: 0 50px;
        border-radius: 5px;
        .title{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 70px 0 20px 0;
            .text{
                font-size: 35px;
                font-weight: 600;
                margin: 0;
            }
            .search{
                border:1px solid rgba(177, 177, 177, 0.671);
                border-radius: 5px;
                display: flex;
                align-items: center;
                padding: 0 10px;
                height: 40px;
                margin-bottom: -10px;
                box-shadow: 0 0 8px 1px rgba(128, 128, 128, 0.233);
                img{
                    width: 28px;
                    padding:0px 8px 0 0;
                    margin-top: -3px;
                }
            
                input{
                    border: none;
                    width: 250px;
                    outline: none;
                    font-size: 18px;
                }
            }
        }
        ul{
            // list-style: none;
            li{
                width: 300px;
                .header{
                    font-size: 25px;
                    width: 500px;
                    margin-top: 80px;
                    a{
                        color: rgba(128, 128, 128, 0.562);
                        font-weight: 600;
                    }
                }
                .block_item{
                    display: flex;
                    flex-wrap: wrap;
                    width: 1200px;
                }
                .block{
                    border:1px solid rgba(85, 85, 85, 0.082);
                    width: 300px;
                    border-radius: 5px;
                    text-align: center;
                    padding: 20px;
                    margin: 20px;
                    transition: all .2s ease-in-out;
                    box-shadow: 0 0 20px 0 rgba(128, 128, 128, 0.082);
                    &:hover{
                        box-shadow: 0 0 20px 0 rgba(128, 128, 128, 0.404);

                    }
                    img{
                        width: 250px;
                        height: 180px;
                    }
                    .title{
                        text-align: left;
                        margin: 20px 0 0px 0;
                        font-weight: 600;
                    }
                    .text{
                        text-align: left;
                        color: gray;
                        line-height: 20px;
                        margin: 0px 0 40px 0;
                    }
                    .price_block{
                        display: flex;
                        a{
                            background-color: #019064;
                            padding: 10px 20px;
                            border-radius: 5px;
                            transition: all .3s ease-in-out;
                            &:hover{
                                background-color: #01906515;
                                color: #019064;
                            }
                        }
                        img{
                            width: 42px;
                            height: 42px;
                            border: 1px solid #01906534;
                            border-radius: 5px;
                            padding:7px;
                            cursor: pointer;
                            margin-left: 5px;
                            transition: all .2s ease-in-out;
                            &:hover{
                                box-shadow: 0 0 3px 0px #21e7ac96;
                            }
                        }
                    }
                }
            }
        }
    }
}