.vnedorojnik{
    .text{
        font-size: 35px;
        font-weight: 600;
        padding: 50px 0 0px 0;
        a{
            color: rgba(128, 128, 128, 0.479);
        }
    }   

    ul{
        padding: 0;
        margin-bottom:150px;
        li{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: 1fr;
            grid-column-gap: 0px;
            grid-row-gap: 0px;
            grid-column-gap: 20px;
            margin-top: 40px;
            a{
                border: 1px solid rgba(128, 128, 128, 0.315);
                border-radius: 10px;
                text-align: center;
                padding: 30px 0;
                transition: all .3s ease-in-out;
                box-shadow: 0 0 10px 1px rgba(128, 128, 128, 0.233);
                img{
                    width: 330px;
                    height: 200px;
                }

                .title{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 80px 20px 0 20px;

                    img{
                        width: 25px;
                        height: 25px;
                    }
                    p{
                        font-size: 20px;
                        font-weight: 600;
                        color: black;
                        margin: 0;
                        text-transform: uppercase;
                    }
                }
                &:hover{
                    margin-top: -15px;
                    box-shadow: 0 10px 3px 0px rgba(128, 128, 128, 0.418);
                    margin-bottom: 15px;
                    
                }
            }
            
        }
    }
}