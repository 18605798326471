.news-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .news-item {
    width: 300px;
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
    padding: 10px;
    text-align: center;
  }
  
  .news-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  h2 {
    font-size: 1.2rem;
    margin: 10px 0;
  }
  
  p {
    font-size: 1rem;
    color: #666;
  }
  
  .a {
    text-decoration: none;
    color: blue;
  }
  