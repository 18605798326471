.news-card {
  display: flex;
  flex-direction: row;
  border: 1px solid #e0e0e0;
  border-radius: 12px; /* Скругляем углы всей карточки */
  overflow: hidden;
  margin: 10px auto; /* Уменьшаем боковые отступы и добавляем автоцентрирование */
  background-color: #fff;
  padding: 10px;
  width: 90%; /* Уменьшаем ширину карточки, чтобы были отступы по бокам */
}

.news-card-image {
  width: 150px; /* Увеличиваем размер изображения */
  height: 150px;
  object-fit: cover;
  margin-right: 15px;
  margin-left: 10px; /* Добавляем отступ слева */
  border-radius: 12px; /* Скругляем углы изображения */
}

.news-card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.news-card-title {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

.news-card-description {
  font-size: 14px;
  color: #555;
  margin: 5px 0;
}

.news-card-footer {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #888;
}
