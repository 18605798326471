.header_one{
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all .1s ease-in-out;
    margin: 20px 0;
    .contact{
        display: flex;

        p{
            display: flex;
            align-items: center;
            margin: 0 10px;
            a{
                color: black;
            }
            a:hover{
                color: #019064;
            }
        }
        img{
            width: 20px;
            margin:-3px 10px 0 0;
        }
    }
    .register{
        a{
            transition: all .3s ease;
            padding: 10px 20px;
            border-radius: 5px;
        }
        .signIN{
            color: white;
            background-color: #019064;
            border: 1px solid #019065a8;
            margin: 0 15px 0 0 ;
        }
        .signIN:hover{
            color: black;
            background-color: white;
            box-shadow: 0 0 5px 1px rgba(66, 194, 66, 0.541);
        }

        .signUP{
            color:#000;
            background-color: white;
            border: 1px solid #01906585;
            box-shadow: 0 0 0px 0px rgba(66, 194, 66, 0.541);
            
        }
        .signUP:hover{
            color:black;
            box-shadow: 0 0 8px 1px rgba(66, 194, 66, 0.521);

        }
    }



    .register a{
        color: #019064;
    }
}

.header_two{
    display: flex;
    justify-content: space-between;
    margin: 30px 0;
    align-items: center;
    .logo{
        display: flex;
        align-items: center;
        img{
            width: 60px;
            height: 60px;
            // margin-top: -7px;
        }
        p{
            font-size: 28px;
            font-weight: 600;
            width:  120px;
            line-height: 30px;
            margin: 0 0 0 15px;
        }
    }
    .search{
        border:1px solid rgba(177, 177, 177, 0.671);
        border-radius: 5px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        height: 40px;
        box-shadow: 0 0 8px 1px rgba(128, 128, 128, 0.233);
        img{
            width: 28px;
            padding:0px 8px 0 0;
            margin-top: -3px;
        }

        input{
            border: none;
            width: 500px;
            outline: none;
            font-size: 18px;
        }
    }
    .search_phone{
        display: none;
    }
    .basket{
        transition: all .2s ease-in-out;
        border: 1px solid #01906557;
        padding: 10px 20px;
        border-radius: 5px;
        color:#019064;
        margin-bottom: -10px;
        svg{
            width: 25px;
            margin: 0 10px 0 0px;
        }
        a{
            display: flex;
            align-items: center;
            color: #000;
            font-size: 19px;
            p{
                margin: 0;
            }
            
        }
        &:hover{
            box-shadow: 0 0 10px 0px rgba(66, 194, 66, 0.527);

        }
    }

    .menu{
        display: none;
        button{
            border:none;
            background-color: white;
            img{
                width: 40px;
            }
        }
        .menu_item{
            background-color: rgba(0, 0, 0, 0.747);
            width: 100%;
            height: 100vh;
            position: absolute;
            left: 0;
            top: 0;
            .item{
                background-color: white;
                width: 500px;
                height: 100vh;
                position: absolute;
                right: 0;
                top: 0;
                opacity: 1;
                .logo{
                    display: flex;
                    justify-content: space-between;
                    margin:20px 20px 0 20px;
                    .logoIKT{
                        width: 200px;
                        height:80px;
                    }
                    .close{
                        width: 40px;
                        opacity: .5;
                        margin-right: 40px;                        
                    }
                }
                .spisok{
                    ul{
                        list-style: none;
                        margin-top: 50px;
                        li{
                            margin:20px 0 0 20px;
                            a{
                                font-size: 20px;
                                color: black;
                            }
                        }

                    }
                }
                .contact{
                    display: flex;
                    flex-direction: column;
                    position: absolute;
                    bottom: 130px;
                    a{
                        color: gray;
                        margin: 5px 50px;
                        img{
                            margin:-3px 5px 0 0;
                            width: 15px;
                        }
                    }
                }
                .register{
                    margin-left: 50px;
                    position: absolute;
                    bottom: 50px;
                    border-top: 1px dashed gray;
                    padding-top: 20px;
                    width: 80%;
                    button{
                        background-color: #019064;
                        color: white;
                        padding: 10px 20px;
                        margin: 0 10px 0 0 ;
                        border-radius: 5px;
                    }
                    .zareg{
                        border:1px solid #019064;
                        background-color: white;
                        color: black;
                    }
                }
            }
        }
    }
}

.header_three{
    background-color: #019064;
    padding:15px 0;
    .header_three_item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        ul{
            list-style: none;
            display: flex;
            margin: 0;
            li{
                margin: 0 20px;
                a{
                    font-size: 20px;
                    font-weight: 600;
                    transition: all .2s ease-in-out;
                    &:hover{
                        text-shadow: 0 0 20px white;
                    }
                }
            }
        }
        .button{
            color: #000;
            background-color: white;
            padding: 10px 20px;
            border-radius: 5px;
            box-shadow: 0 0 15px 0 #a7fcd1;
            display: flex;
            align-items: center;
            transition: all .2s ease-in-out;
            &:hover{
                box-shadow: 0 0 0px 0 #a7fcd1;

            }
            img{
                width: 15px;
                transform: rotate(90deg);
                margin: 0 0 0 10px;
            }
        }
    }
}


