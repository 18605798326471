/* ExcelAgGrid.css */
.container {
  margin: 20px;
  font-family: 'Arial', sans-serif;
}

h1 {
  text-align: center;
  color: #333;
}

input[type="file"] {
  display: block;
  margin: 20px auto;
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

input[type="file"]:hover {
  background-color: #e9e9e9;
}

button {
  display: block;
  margin: 0 auto 20px auto;
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #218838;
}

button:active {
  background-color: #1e7e34;
}

.ag-theme-alpine {
  height: 400px;
  width: 100%;
  margin-top: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.ag-header-cell {
  background-color: #007bff !important;
  color: white !important;
  font-weight: bold;
}

.ag-row {
  background-color: #f9f9f9;
}

.ag-row:nth-child(even) {
  background-color: #f1f1f1;
}

.ag-cell {
  padding: 10px;
  font-size: 14px;
}

.ag-theme-alpine .ag-root-wrapper {
  border-radius: 10px;
}
