.home_footer{
    width: 100%;
    background-color: #ffffffaf;
    height: 500px;
    margin-top: 190px;
    padding: 70px 0;
    border-top: 1px solid rgba(128, 128, 128, 0.274);
    box-shadow: -5px 0 25px 2px gray;
    ul{
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
        .logo{
            p{
                color: gray;
                font-size: 15px;
                margin: 20px 0 0 20px;
            }
            .logo_item{
                display: flex;
                align-items: center;
                img{
                    width: 80px;
                }
                p{
                    color: black;
                    font-size: 35px;
                    width: 150px;
                    line-height: 35px;
                    margin:0 0 0 10px;
                    font-weight: 600;
                }
            }
        }
        .footer_categories{
            display: flex;
            flex-direction: column;
            margin: 0 0 0 150px;
            width: 135px;
            p{
                font-size: 25px;
                font-weight: 600;
                margin: 0 0 0 -10px;
            }
            a{
                color: black;
                transition: margin .2s ease-in-out;
                &:hover{
                    color: #019064;
                    margin-left: 10px;
                }
            }
        }

        .footer_about{
            display: flex;
            flex-direction: column;
            margin: 0 0 0 150px;
            p{
                font-size: 20px;
                font-weight: 600;
                margin: 0 0 0 -10px;
            }
            a{
                color: black;
                transition: margin .2s ease-in-out;
                &:hover{
                    color: #019064;
                    margin-left: 10px;
                }
            }

        }

        .footer_socialMedia{
            display: flex;
            flex-direction: column;
            margin: 0 0 0 150px;
            p{
                font-size: 18px;
                font-weight: 600;
                margin: 0 0 0 -10px;
            }
            .item{
                display: flex;
                margin-top: 10px;
                img{
                    width: 40px;
                    margin-left: 5px;
                }
            }
        }

    }

    .info{
        display: flex;
        flex-direction: column;
        position: absolute;
        right: 880px;
        bottom:-580px;
        li{
            display: flex;
            align-items: center;
            margin-top: 10px;
            p{
                margin: 0 0 0 10px;
                width: 300px;
            }
            img{
                width: 20px;
                height: 20px;
            }
        }
    }
    .footer_cars{
        display: flex;
        justify-content: space-between;
        position: relative;
        img{
            position: absolute;
            bottom: -100px;
            width: 680px;
            height: 350px;
            
        }
        .ekskovator{
            left: -750px;

        }
        .samosval{
            right: -750px;
        }
        
        
    }
}