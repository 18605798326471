.categories{
    .title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .text{
            font-size: 35px;
            font-weight: 600;
            padding: 50px 0 50px 0;
        }
        button{
            width: 200px;
            height: 50px;
            background-color: rgba(0, 128, 0, 0.781);
            color: white;
            border-radius: 5px;
            font-size: 18px;
        }
        
    }

    .addCategory{
        background-color: rgba(0, 0, 0, 0.726);
        width: 100%;
        height: 100%;
        max-height: 100vh;
        position: absolute;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .item{
            background-color: white;
            width: 1200px;
            height: 500px;
            border-radius: 5px;
            padding: 50px;
            .item_logo{
                display: flex;
                justify-content: space-between;
                align-items: center;

                .logo{
                    width: 250px;
                }
                .close{
                    width: 40px;
                    opacity: .5;
                    margin-top: -100px;
                    margin-right: -15px;
                    cursor: pointer;
                    &:hover{
                        opacity: 1;
                    }
                }
            } 
            form{
                display: flex;
                flex-direction: column;
                align-items: center;
                input{
                    width: 550px;
                    padding: 10px;
                    border-radius: 5px;
                    margin: 10px 0;
                    border:1px solid rgba(128, 128, 128, 0.514);
                    font-size: 20px;
                }
                button{
                    background-color: green;
                    color: white;
                    font-size: 20px;
                    width: 550px;
                    padding: 10px;
                    border-radius: 5px;
                    margin: 20px 0 0 0;
                    &:hover{

                    }
                }   

            } 
        }   
    }
    ul{
        padding: 0;
        li{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: 1fr;
            grid-column-gap: 0px;
            grid-row-gap: 40px;
            grid-column-gap: 30px;
            a{
                border: 1px solid rgba(128, 128, 128, 0.315);
                border-radius: 10px;
                text-align: center;
                padding: 30px 0;
                transition: all .3s ease-in-out;
                box-shadow: 0 0 10px 1px rgba(128, 128, 128, 0.233);

                &:hover{
                    margin-top: -15px;
                    box-shadow: 0 10px 3px 0px rgba(128, 128, 128, 0.418);
                    margin-bottom: 15px;
                    
                }

                img{
                    width: 330px;
                    height: 230px;
                }

                .title{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 80px 20px 0 20px;

                    img{
                        width: 25px;
                        height: 25px;
                    }
                    p{
                        font-size: 20px;
                        font-weight: 600;
                        color: black;
                        margin: 0;
                    }
                }
               
            }
            
        }
    }
}