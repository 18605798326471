.registrationForm {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 15px;
    color: #333;
  }
  
  .formGroup {
    margin-bottom: 15px;
  }
  
  .label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
  }
  
  .input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  .submitButton {
    width: 100%;
    padding: 10px;
    background-color: #019064;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .submitButton:hover {
    background-color: white;
  }
  
  .error {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }
  
  .successMessage {
    text-align: center;
    font-size: 18px;
    color: green;
  }

  .buttonStyle {
    padding: 5px 5px;
    margin-bottom: 15px;
    font-size: 10px;
    background-color: #019064;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .buttonStyle:hover {
    transition: all .3s ease;
    /* color: black; */
    /* background-color: white; */
    box-shadow: 0 0 5px 1px rgba(66, 194, 66, 0.541);
  }