.homeMain{

    .button{
        img{
            cursor: pointer;
            width: 30px;
            height: 30px;
            position: absolute;
            transition: all .2s ease-in-out;
            &:hover{
                width: 35px;
                height: 35px;
            }
        }

        .left{
            transform: rotate(180deg);
            left: 60px;
            &:hover{
                left: 50px;
            }
        }
        .right{
            right: 60px;
            &:hover{
                right: 50px;
            }
        }
    }

    .home_categories{
        margin: 150px 0 50px 0;
        .ekskovator{
            position: absolute;
            width: 1200px;
            z-index: 0;
            right: -320px;
            margin-top: 400px;
        }
        .kran{
            position: absolute;
            margin-top: 750px;
                        
        }
        .title{
            display: flex;
            justify-content: space-between;
            p{
                font-family: "Stick No Bills", sans-serif;
                font-optical-sizing: auto;
                font-style: normal;
                margin: 0;
                font-size: 35px;
                font-weight: 600;
            }
        }

        ul{
            padding: 0;
            margin-bottom:50px;
            position: relative;
            li{
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: 1fr;
                grid-column-gap: 0px;
                grid-row-gap: 0px;
                grid-column-gap: 20px;
                margin-top: 40px;
                a{
                    background-color: white;
                    border: 1px solid rgba(128, 128, 128, 0.315);
                    border-radius: 10px;
                    text-align: center;
                    padding: 30px 0;
                    transition: all .3s ease-in-out;
                    box-shadow: 0 0 10px 1px rgba(128, 128, 128, 0.233);
                    img{
                        width: 330px;
                        height: 200px;
                    }
    
                    .title{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 80px 20px 0 20px;
    
                        img{
                            width: 25px;
                            height: 25px;
                        }
                        p{
                            font-size: 20px;
                            font-weight: 600;
                            color: black;
                            margin: 0;
                            text-transform: uppercase;
                        }
                    }
                    &:hover{
                        margin-top: -15px;
                        box-shadow: 0 10px 3px 0px rgba(128, 128, 128, 0.418);
                        margin-bottom: 15px;
                        
                    }
                }
                
            }
        }
        .next{
            font-size: 20px;
            opacity: .5;
            color: #019064;
            border:1px solid #019064;
            padding: 10px 20px;
            border-radius: 5px;
            transition: opacity .2s ease;
            font-weight: 600;
            width: 200px;
            text-align: center;

            &:hover{
                opacity: 1;
            }
        }
    }


    .home_news{
        margin: 50px 0 150px 0;
        h2{
            font-size: 40px;
            color: black;
            text-align: center;
            padding: 30px 0;
            width: 100%;
            margin: 0 auto;
            font-weight: 600;
        }
        .item{
            background-image: url('../../../../public/img/BackgroundNews.png');
            width: 100%;
            height: 650px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            padding: 130px 0;
            .item_main{
                .title{
                    color: white;
                    font-weight: 600;
                    font-size: 30px;
                    text-align: center;
                    margin: 30px 0;
                }
                .text{
                    color: white;
                    font-weight: 600;
                    text-align: center;
                    font-size: 25px;
                }
                button{
                    background-color: rgba(128, 128, 128, 0);
                    border: none;
                    img{
                        position: absolute;
                    }
                    .left{
                        right: 0;
                    }
                }
            }
        }
        
    }

    .home_forms{
        margin: 50px 0;
        .forms_item{
            .title{
                display: flex;
                align-items: center;
                margin-bottom: 30px;
                img{
                    width: 70px;
                    margin-right: 20px;
                }
                b{
                    font-size: 35px;
                }
                p{
                    margin:-5px 0 0  0;
                }
            }
            form{
                input{
                    padding:10px 10px;
                    border:1px solid rgba(128, 128, 128, 0.377);
                    border-radius: 5px;
                    margin:10px 20px 10px 0;
                    width: 350px;
                }
                textarea{
                    padding:10px 10px;
                    border:1px solid rgba(128, 128, 128, 0.377);
                    border-radius: 5px;
                    margin:10px 20px 10px 0;
                    width: 350px;
                }
                button{
                    border:1px solid rgba(128, 128, 128, 0.212);
                    padding: 5px 30px;
                    border-radius: 5px;
                    background-color: #019064;
                    color: white;
                    font-size: 20px;
                    box-shadow: 0 0 10px 5px #0190653f;
                    transition: all .2s ease-in-out;
                    margin: 20px 0 0 0;
                    &:hover{
                        box-shadow: 0 0 0 0 white;
                    }
                    
                }
            }
        }
    }

    
}



@media (max-width:1400px){
    .homeMain{
        .home_slider{
            .slider{
                max-width: 1097px;
                width: 100%;
                margin-left: 40px;
            }
        }
    }
}