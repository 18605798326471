.home_slider {
    position: relative;
    width: 100%;
    overflow: hidden;
    background-image: url('../../../../../public/img/Garaj.png');
    height: 700px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    backdrop-filter: blur(5px);
    .container {
      display: flex;
      justify-content: center;
  
      .slider {
        position: absolute;
            background-color: white;
            justify-content:space-around;
            display: flex;
            margin: auto;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            align-items: center;
            border-radius: 10px;
            width: 100%;
            box-shadow: 0 0px 25px 12px #019064;
        .text {
          padding: 20px;
          width: min($numbers: 100);
          .title {
            font-size: 24px;
            font-weight: bold;
          }
            .slider_flex{
                display: flex;
                align-items: center;
                flex: 1 1 auto;
            }
          ul {
            list-style-type: none;
            padding: 0;
            left: auto;
            li {
              margin: 10px 0;
              display: flex;
              align-items: center;
  
              img {
                margin-right: 10px;
              }
            }
          }
          button{
            border: 1px solid #019064;
            background-color: #019064;
            padding: 10px 20px;
            font-size: 20px;
            border-radius: 5px;
            cursor: pointer;
            color: white;
            transition: all .2s ease-in-out;
            &:hover{
                background-color: white;
                color: black;
                box-shadow: 0 0 10px 0px #01906552;
            }
        }
        }
  
        .ekskovator {
          width: 300px;
          height: auto;
          left: auto;
        }
  
        .button {
          position: absolute;
          top: 50%;
          width: 100%;
          display: flex;
          justify-content: space-between;
  
          .left, .right {
            cursor: pointer;
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }
  