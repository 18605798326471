.toyota_Lc200_Electric{
    .title{
        .text{
            font-size: 35px;
            font-weight: 600;
            padding: 70px 0 10px 0;
            a{
                color: rgba(128, 128, 128, 0.479);
            }
            
        }   
    }
    .text{
        font-size: 30px;
        font-weight: 600;
        margin: 10px 0;
    }
    ul{
        padding: 0;
        list-style: none;
        li{
            margin: 25px 0;
            width: 100%;
            border:1px solid #01906541;
            border-radius: 5px;
            display: flex;
            padding: 10px 20px;
            align-items: center;
            font-size: 20px;
            justify-content: space-between;
            box-shadow: 0 0 10px 0 #01906541;
            p{
                margin: 0;
                font-weight: 600;
            }
            .name{
                width: 70%;

            }
            button{
                background-color: #019064;
                padding: 10px 20px;
                border-radius: 5px;
                border:1px solid #019064;
                transition: all .2s ease-in-out;
                color: white;
                &:hover{
                    background-color: #fff;
                    color:#019064;
                }
            }
        }
    }
    .info{
        background-color: rgba(0, 0, 0, 0.582);
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        top: 0;
        flex-direction: column;
        justify-content: center;
        .item{
            background-color: white;
            width: 100%;
            height: 700px;
            border-radius: 10px;
            padding:20px 0 50px 0;
            overflow: auto;
            margin: 0% 0 0 0 ;
            .header{
                display: flex;
                justify-content: space-between;

                .logo{
                    display: flex;
                    align-items: center;
                    margin:20px 0 20px 70px;
                    img{
                        width: 70px;
                        height: 70px;
                    }
                    p{
                        font-size: 25px;
                        font-weight: 600;
                        width: 150px;
                        line-height: 30px;
                        margin: 0 0 0 10px;
                    }
                }
                .close{
                    width: 35px;
                    margin: 10px 50px 0 0;
                    opacity: 0.3;
                    transition: all .1s ease-in-out;
                    &:hover{
                        opacity: .7;
                        cursor: pointer;
                    }
                }
            
            
            }
        }
        ul{
            width: 1000px;
            margin: 0 auto;
            li{
                padding: 10px 20px;
                box-shadow: 0 0 0 0;
                cursor: pointer;
                transition: all .2s ease;
                &:hover{
                    box-shadow: 0 0 10px 0 #0190653f;
                }
                .nameVs{
                    width: 80%;
                }
                .nomer{
                    width: 15%;
                    text-align: center;
                    color: #019064;
                }
            }
        }
    }

    tr{
        text-align: center;
        th{
            border: 1px solid gray;
            border-radius: 5px;
            padding: 20px;
        }
        td{
            border: 1px solid gray;
            border-radius: 5px;
            padding: 20px;
            button{
                padding: 10px;
                background-color: #019064;
                color: white;
                border:none;
                border-radius: 5px;
            }
        }
    }
}